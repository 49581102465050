<app-common-dialog-form
    [delegate]="this"
    saveText="Päivitä"
    [dialogTitle]="title()"
    [rightTitle]="pilotage.broker?.displayName">

    <app-vertical-form>
        <app-input-row>
            <app-datetime-field [label]="startTimeLabel" [control]="form.controls.eta" [required]="true"/>
            <app-schedule-source-field label="Aikataulutiedon lähde" [control]="form.controls.source" [type]="selectableSourceTypes"/>
        </app-input-row>

        @if (showPilotBoardingTime || pilotage.pilot != null) {
            <app-input-row>
                @if (showPilotBoardingTime) {
                    <mat-form-field>
                        <mat-label>Toimitusaika</mat-label>
                        <app-mat-date-time-picker [formControl]="form.controls.pilotBoardingTime" [required]="true"/>
                        <mat-icon matIconSuffix>calendar_today</mat-icon>
                        @if (originalPilotEta(); as original) {
                            <mat-hint>Toimitusaikaa siirretty (oli <a href="" tabindex="-1" (click)="resetPilotEtaToOriginal($event)">{{ original | helsinkiDate:'HH:mm' }})</a></mat-hint>
                        }
                    </mat-form-field>
                } @else {
                    <div></div>
                }

                <div class="flex flex-row justify-between items-center gap-2">
                    @if (pilotage.pilot; as pilot1) {
                        <mat-form-field class="fill-remaining-space w-0">
                            <mat-label>Herätys {{ pilot1.initials }}</mat-label>
                            <mat-select [formControl]="form.controls.pilot1AlarmTimeDifference">
                                <mat-select-trigger>{{ formatAlarmTimeDifference(form.controls.pilot1AlarmTimeDifference.value) }}
                                    <small>({{ alarmTime(form.controls.pilot1AlarmTimeDifference.value) | helsinkiDate: 'HH:mm' }})</small>
                                </mat-select-trigger>

                                @for (d of selectableAlertDeltas(); track $index) {
                                    <mat-option [value]="d.minutes" class="h-[50px] leading-none">
                                        <div>{{ d.duration | duration }}</div>
                                        <div class="text-xs text-de-emphasized">
                                            {{ d.time | helsinkiDate:'HH:mm' }}
                                        </div>
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    }

                    @if (pilotage.pilot2; as pilot2) {
                        <mat-form-field class="fill-remaining-space w-0">
                            <mat-label>Herätys {{ pilot2.initials }}</mat-label>
                            <mat-select [formControl]="form.controls.pilot2AlarmTimeDifference">
                                <mat-select-trigger>{{ formatAlarmTimeDifference(form.controls.pilot2AlarmTimeDifference.value) }}
                                    <small>({{ alarmTime(form.controls.pilot2AlarmTimeDifference.value) | helsinkiDate: 'HH:mm' }})</small>
                                </mat-select-trigger>
                                @for (d of selectableAlertDeltas(); track $index) {
                                    <mat-option [value]="d.minutes" class="h-[50px] leading-none">
                                        <div>{{ d.duration | duration }}</div>
                                        <div class="text-xs text-de-emphasized">
                                            {{ d.time | helsinkiDate:'HH:mm' }}
                                        </div>
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    }
                </div>
            </app-input-row>
        }

        <div class="grid grid-cols-2 gap-2">
            <mat-form-field>
                <mat-label>Reitti</mat-label>
                <app-select-route [formControl]="form.controls.routeId" [defaultRouteIds]="details.defaultRouteIds" [allowedRouteIds]="details.compatibleRouteIds" required="true"></app-select-route>
                <mat-hint align="end" style="white-space: pre-line; text-align: right">{{ routeHint() }}</mat-hint>
            </mat-form-field>
            <app-edit-draft-fields [controls]="form.controls" [vesselDraftBounds]="pilotage.vesselDraftBounds"/>
        </div>

        @if (form.controls.removeAnchorage.enabled) {
            <div class="py-6 px-0 pt-2">
                <p class="text-orange">Reitin alkupiste on vaihtunut, mutta tilauksella on saapumisaika ulkoankkuriin.</p>
                <p>Poistetaanko ankkuritieto?

                    <mat-radio-group [formControl]="form.controls.removeAnchorage" class="space-x-2">
                        <mat-radio-button [value]="true">Poista</mat-radio-button>
                        <mat-radio-button [value]="false">Säilytä</mat-radio-button>
                    </mat-radio-group>
                </p>
            </div>
        }

        <app-input-row>
            <app-berth-field
                label="Alkulaituri"
                [control]="form.controls.startBerthId"
                [endpointId]="selectedRoute()?.startId"
                [hint]="(pilotage.route.start.berth && form.controls.startBerthId.value !== pilotage.route.start.berth.id) ? ('Alkuperäinen: ' + pilotage.route.start.berth.code) : null"/>
            <app-berth-field
                label="Loppulaituri"
                [control]="form.controls.endBerthId"
                [endpointId]="selectedRoute()?.endId"
                [hint]="(pilotage.route.end.berth && form.controls.endBerthId.value !== pilotage.route.end.berth.id) ? ('Alkuperäinen: ' + pilotage.route.end.berth.code) : null"/>
        </app-input-row>

        <div class="grid grid-cols-4 gap-2">
            <mat-form-field>
                <mat-label>Hinaajat</mat-label>
                <app-select-tugs-requested [formControl]="form.controls.tugsRequested"/>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tilattu</mat-label>
                <mat-select [formControl]="form.controls.tugOrderStatus">
                    <mat-option [value]="null">-</mat-option>
                    <mat-option [value]="TugOrderStatus.UNORDERED">Ei tilattu</mat-option>
                    <mat-option [value]="TugOrderStatus.ORDERED">Tilattu</mat-option>
                </mat-select>
            </mat-form-field>

            <app-text-field class="col-span-2" label="Hinaajan huomautus" [control]="form.controls.tugNotice"/>
        </div>

        <mat-form-field>
            <mat-label>Vakiohuomautus</mat-label>
            <mat-select [formControl]="form.controls.standardNoticeId" #standardNoticeField>
                <mat-option [value]="null">-</mat-option>
                @for (notice of standardNotices.value(); track notice.id) {
                    <mat-option [value]="notice.id">{{ notice.label }}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <app-textarea-field label="Huomautus" [control]="form.controls.notice"/>
        <app-textarea-field label="Laskutuksen huomautus" [control]="form.controls.billingNotice"/>

        @if (form.controls.latePilotBoardingTimeReason.enabled) {
            <mat-form-field>
                <mat-label>Myöhäisen toimitusajan syy</mat-label>
                <mat-select [formControl]="form.controls.latePilotBoardingTimeReason">
                    @for (v of latePilotBoardingTimeReasonSelections; track v.value) {
                        <mat-option [value]="v.value">{{ v.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </app-vertical-form>

</app-common-dialog-form>
