import { ChangeDetectionStrategy, Component, computed, effect, Inject, Optional, resource, Resource, Signal } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BerthId, FindRouteDto, MakeOrderOrNoticeParams, PilotageDetails, PilotageDirection, PilotageEditDetails, PilotageEndpoint, PilotageNoticeEndpoint, PilotageScheduleSource, RouteEndpoint, RouteId, StandardNoticeDto, StandardNoticeId, TugOrderStatus, TugsRequested } from "apina-frontend";
import { asRequired, controlValues, controlValuesSignal, disableWhen, enableWhenNull, PilotageState, unsubscribeWhenDestroyed, validateWhen } from "common";
import { LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS, needsLatePilotBoardingTimeReason } from "../../domain/pilot-boarding-time";
import { ActivatedRoute } from "@angular/router";
import { SelectScheduleSourceType } from "../../common/select-schedule-source/select-schedule-source.component";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { Duration, Instant } from "@js-joda/core";
import { describePilotage } from "../../domain/pilotage";
import { firstNoticeTimeForDirection } from "../../domain/terms-of-service";
import { disableTugOrderStatusWhenNeeded } from "../../domain/tugs";
import { newPilotDeliveryActionsForm, PilotDeliveryActionsComponent, resolveActionsTakenToDeliverPilot } from "../pilot-delivery-actions/pilot-delivery-actions.component";
import { SelectTugsRequestedComponent } from "../../common/select-tugs-requested/select-tugs-requested.component";
import { createDraftControls, EditDraftFieldsComponent } from "../../common/edit-draft-fields/edit-draft-fields.component";
import { SelectRouteComponent } from "../../common/select-route/select-route.component";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";
import { ScheduleSourceFieldComponent } from "../../forms/schedule-source-field/schedule-source-field.component";
import { ReadonlyFieldComponent } from "../../forms/readonly-field/readonly-field.component";
import { BerthFieldComponent } from "../../forms/berth-field/berth-field.component";
import { TextFieldComponent } from "../../forms/text-field/text-field.component";
import { TextareaFieldComponent } from "../../forms/textarea-field/textarea-field.component";

/**
 * Dialog for making orders or notices.
 */
@Component({
    templateUrl: './make-order.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonDialogFormComponent,
        EditDraftFieldsComponent,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        TextFieldModule,
        PilotDeliveryActionsComponent,
        ReactiveFormsModule,
        SelectRouteComponent,
        SelectTugsRequestedComponent,
        VerticalFormComponent,
        InputRowComponent,
        DatetimeFieldComponent,
        ScheduleSourceFieldComponent,
        ReadonlyFieldComponent,
        BerthFieldComponent,
        TextFieldComponent,
        TextareaFieldComponent,
    ]
})
export class MakeOrderComponent implements CommonDialogFormDelegate {

    readonly details: PilotageEditDetails;
    readonly pilotage: PilotageDetails;
    readonly form = new FormGroup({
        time: new FormControl<Instant>(null!, {nonNullable: true, validators: Validators.required}),
        source: new FormControl<PilotageScheduleSource>(null!, {nonNullable: true, validators: Validators.required}),
        ...createDraftControls(),
        standardNoticeId: new FormControl<StandardNoticeId | null>(null),
        notice: new FormControl<string | null>(null),
        billingNotice: new FormControl<string | null>(null),
        pilotBoardingTime: new FormControl<Instant | null>(null),
        routeId: new FormControl<RouteId>(null!, {nonNullable: true, validators: Validators.required}),
        startBerth: new FormControl<BerthId | null>(null),
        endBerth: new FormControl<BerthId | null>(null),
        latePilotBoardingTimeReason: new FormControl(null),
        tugsRequested: new FormControl<TugsRequested | null>(null),
        tugOrderStatus: new FormControl<TugOrderStatus | null>(null),
        tugNotice: new FormControl<string | null>(null),
        actionsTakenToDeliverPilot: newPilotDeliveryActionsForm()
    });

    readonly unknownDraft = new FormControl(false, {nonNullable: true});
    readonly draftRequired: Signal<boolean>;

    readonly title: Signal<string>;
    readonly action: string;
    readonly etaLabel: string;
    private readonly target: TargetState;
    readonly selectedRoute: Signal<FindRouteDto | undefined>;
    readonly showPilotBoardingTime: boolean;
    readonly needsLatePilotBoardingTimeReason: Signal<boolean>;
    readonly selectableSourceType: SelectScheduleSourceType;
    readonly standardNotices: Resource<StandardNoticeDto[]>;
    readonly latePilotBoardingTimeReasonSelections = LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS;
    readonly TugOrderStatus = TugOrderStatus;
    readonly hasTosViolations: Signal<boolean>;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) params: MakeOrderComponentParams | undefined,
        route: ActivatedRoute,
        private readonly pilotageEndpoint: PilotageEndpoint,
        pilotageNoticeEndpoint: PilotageNoticeEndpoint,
        routeEndpoint: RouteEndpoint,
    ) {
        this.details = params != null ? params.details : route.snapshot.data['details'];
        this.target = params != null ? params.target : route.snapshot.data["target"];

        this.pilotage = this.details.pilotage;
        const pilotage = this.pilotage;

        switch (this.target) {
            case PilotageState.ORDER:
                this.action = "Tee tilaus";
                this.etaLabel = "Tilausaika";
                this.selectableSourceType = SelectScheduleSourceType.ORDER;
                break;
            case PilotageState.NOTICE:
                this.action = "Tee ennakko";
                this.etaLabel = "ETA/ETD";
                this.selectableSourceType = SelectScheduleSourceType.NOTICE;
                break;
        }

        this.standardNotices = resource({loader: () => pilotageNoticeEndpoint.getPilotageStandardNotices(pilotage.standardNotice?.id ?? null)});

        const currentTime = Instant.now();
        const etaSignal = controlValuesSignal(this.form.controls.time);

        this.hasTosViolations = computed(() => violatesTos(this.details, etaSignal(), currentTime));

        disableTugOrderStatusWhenNeeded(this.form.controls.tugsRequested, this.form.controls.tugOrderStatus);

        enableWhenNull(this.form.controls.draft, [this.unknownDraft]);
        disableWhen(this.unknownDraft, [this.form.controls.draft, this.form.controls.draftAft, this.form.controls.draftFore]);
        unsubscribeWhenDestroyed(controlValues(this.unknownDraft).subscribe(() => this.form.controls.draft.updateValueAndValidity()));

        this.showPilotBoardingTime = this.target === PilotageState.ORDER && pilotage.pilot != null;

        // Reset pilot boarding time when user changes ETA, unless user has already changed the pilot boarding time
        if (this.showPilotBoardingTime) {
            this.form.controls.time.valueChanges.subscribe(v => {
                if (this.form.controls.pilotBoardingTime.pristine)
                    this.form.controls.pilotBoardingTime.reset(v);
            });
        }

        const routes = resource({ loader: () => routeEndpoint.getRoutes()});
        const selectedRouteId = controlValuesSignal(this.form.controls.routeId);

        this.selectedRoute = computed(() => {
            const routeId = selectedRouteId();
            return routes.value()?.find(it => it.id === routeId);
        });

        this.draftRequired = computed(() => requiresDraft(this.target, etaSignal(), this.selectedRoute()?.direction, currentTime));
        this.form.controls.draft.addValidators(validateWhen(Validators.required, () => this.draftRequired() && !this.unknownDraft.value));
        effect(() => {
            this.draftRequired(); // update validity whenever the required state changes
            this.form.controls.draft.updateValueAndValidity();
        });

        this.form.reset({
            time: pilotage.eta,
            routeId: pilotage.route.id,
            startBerth: pilotage.route.start?.berth?.id ?? null,
            endBerth: pilotage.route.end?.berth?.id ?? null,
            draft: pilotage.drafts.max,
            draftFore: pilotage.drafts.fore,
            draftAft: pilotage.drafts.aft,
            airDraft: pilotage.drafts.air,
            standardNoticeId: pilotage.standardNotice?.id ?? null,
            notice: pilotage.notice,
            billingNotice: pilotage.billingNotice,
            pilotBoardingTime: this.showPilotBoardingTime ? pilotage.startTime : null,
            tugsRequested: pilotage.tugsRequested,
            tugOrderStatus: pilotage.tugOrderStatus,
            tugNotice: pilotage.tugNotice
        });

        // Reset berths when user changes route
        let previousStart = this.pilotage.route.start.id;
        let previousEnd = this.pilotage.route.end.id;
        effect(() => {
            const route = this.selectedRoute();
            if (route !== undefined) {
                if (route.startId !== previousStart) {
                    this.form.controls.startBerth.reset(null);
                    previousStart = route.startId;
                }
                if (route.endId !== previousEnd) {
                    this.form.controls.endBerth.reset(null);
                    previousEnd = route.endId;
                }
            }
        });

        this.title = computed(() => describePilotage(pilotage, this.selectedRoute()));

        const time = controlValuesSignal(this.form.controls.time);
        const pilotBoardingTime = controlValuesSignal(this.form.controls.pilotBoardingTime);
        this.needsLatePilotBoardingTimeReason = computed(() => needsLatePilotBoardingTimeReason(pilotage.eta, null, time(), pilotBoardingTime(), pilotage.state));
    }

    doSave(): Promise<void> {
        const data: MakeOrderOrNoticeParams = {
            ...asRequired(this.form.value),
            actionsTakenToDeliverPilot: resolveActionsTakenToDeliverPilot(this.form.controls.actionsTakenToDeliverPilot),
        };

        switch (this.target) {
            case PilotageState.ORDER:
                return this.pilotageEndpoint.makeOrder(this.pilotage.id, data);
            case PilotageState.NOTICE:
                return this.pilotageEndpoint.makeNotice(this.pilotage.id, data);
        }
    }

    protected readonly violatesTos = violatesTos;
}

function violatesTos(details: PilotageEditDetails, eta: Instant | null, now: Instant): boolean {
    if (details.hasTosViolations)
        return true;

    if (eta == null)
        return false;

    const orderMargin = Duration.between(now, eta).toMinutes();
    if (orderMargin < details.orderValidationRules.orderPeriod)
        return true;

    const allowedTimeRange = details.orderValidationRules.allowedTimeRange;
    if (allowedTimeRange != null)
        return !allowedTimeRange.contains(eta);

    return false;
}

function requiresDraft(target: TargetState, eta: Instant | null, direction: PilotageDirection | null | undefined, now: Instant): boolean {
    switch (target) {
        case PilotageState.ORDER:
            return true;
        case PilotageState.NOTICE:
            return direction != null && eta != null && Duration.between(now, eta).compareTo(firstNoticeTimeForDirection(direction)) <= 0;
    }
}

type TargetState = PilotageState.NOTICE | PilotageState.ORDER;

export interface MakeOrderComponentParams {
    details: PilotageEditDetails,
    target: TargetState;
}
