<app-common-dialog-form
    [delegate]="this"
    [dialogTitle]="title()"
    [rightTitle]="pilotage.broker?.displayName"
    [saveText]="action">

    <app-vertical-form>
        <app-input-row>
            <app-datetime-field [label]="etaLabel" [control]="form.controls.time" [required]="true"/>
            <app-schedule-source-field label="Aikataulutiedon lähde" [control]="form.controls.source" [type]="selectableSourceType"/>
        </app-input-row>

        @if (showPilotBoardingTime) {
            <app-input-row>
                <app-datetime-field label="Toimitusaika" [control]="form.controls.pilotBoardingTime"/>
                <app-readonly-field label="Luotsi" [value]="pilotage.pilot?.initials ?? ''"/>
            </app-input-row>
        }

        <div class="grid grid-cols-2 gap-2">
            <mat-form-field>
                <mat-label>Reitti</mat-label>
                <app-select-route [formControl]="form.controls.routeId" [defaultRouteIds]="details.defaultRouteIds" [allowedRouteIds]="details.compatibleRouteIds" required="true"></app-select-route>
                @if (form.controls.routeId.value !== pilotage.route.id) {
                    <mat-hint align="end">Alkuperäinen: {{ pilotage.route.name }}</mat-hint>
                }
            </mat-form-field>
            <app-edit-draft-fields [controls]="form.controls" [vesselDraftBounds]="pilotage.vesselDraftBounds"/>
        </div>

        @if (draftRequired()) {
            <mat-checkbox class="text-xs text-end" [formControl]="unknownDraft">Syväys ei ole tiedossa</mat-checkbox>
        }

        <app-input-row>
            <app-berth-field
                label="Alkulaituri"
                [control]="form.controls.startBerth"
                [endpointId]="selectedRoute()?.startId"
                [hint]="(pilotage.route.start.berth && form.controls.startBerth.value !== pilotage.route.start.berth.id) ? ('Alkuperäinen: ' + pilotage.route.start.berth.code) : null"
            />

            <app-berth-field
                label="Loppulaituri"
                [control]="form.controls.endBerth"
                [endpointId]="selectedRoute()?.endId"
                [hint]="(pilotage.route.end.berth && form.controls.endBerth.value !== pilotage.route.end.berth.id) ? ('Alkuperäinen: ' + pilotage.route.end.berth.code) : null"
            />
        </app-input-row>

        <div class="grid grid-cols-4 gap-2">
            <mat-form-field>
                <mat-label>Hinaajat</mat-label>
                <app-select-tugs-requested [formControl]="form.controls.tugsRequested"/>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Tilattu</mat-label>
                <mat-select [formControl]="form.controls.tugOrderStatus">
                    <mat-option [value]="null">-</mat-option>
                    <mat-option [value]="TugOrderStatus.UNORDERED">Ei tilattu</mat-option>
                    <mat-option [value]="TugOrderStatus.ORDERED">Tilattu</mat-option>
                </mat-select>
            </mat-form-field>
            <app-text-field class="col-span-2" label="Hinaajan huomautus" [control]="form.controls.tugNotice"/>
        </div>

        <mat-form-field>
            <mat-label>Vakiohuomautus</mat-label>
            <mat-select [formControl]="form.controls.standardNoticeId" #standardNoticeField>
                <mat-option [value]="null">-</mat-option>
                @for (notice of standardNotices.value(); track notice.id) {
                    <mat-option [value]="notice.id">{{ notice.label }}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <app-textarea-field label="Huomautus" [control]="form.controls.notice"/>
        <app-textarea-field label="Laskutuksen huomautus" [control]="form.controls.billingNotice"/>

        <app-pilot-delivery-actions
            [form]="form.controls.actionsTakenToDeliverPilot"
            [hasTosViolations]="hasTosViolations()"
            [orderTime]="form.controls.time.value"
            [pilotBoardingTime]="form.controls.pilotBoardingTime.value"/>
    </app-vertical-form>
</app-common-dialog-form>
