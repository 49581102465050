import { booleanAttribute, ChangeDetectionStrategy, Component, effect, Injector, Input, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { controlValuesSignal, toggleEnabled } from "common";
import { Instant } from "@js-joda/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatInputModule } from "@angular/material/input";
import { TextareaFieldComponent } from "../../forms/textarea-field/textarea-field.component";

@Component({
    selector: 'app-pilot-delivery-actions',
    templateUrl: './pilot-delivery-actions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        TextFieldModule,
        ReactiveFormsModule,
        TextareaFieldComponent,
    ]
})
export class PilotDeliveryActionsComponent implements OnInit {

    @Input({required: true})
    form!: PilotDeliveryActionsForm;

    private readonly _hasTosViolations = signal(false);
    private readonly _orderTime = signal<Instant | null>(null);
    private readonly _pilotBoardingTime = signal<Instant | null>(null);
    readonly showForm = signal(false);
    readonly showDetails = signal(false);

    @Input({required: true, transform: booleanAttribute})
    set hasTosViolations(hasTosViolations: boolean) {
        this._hasTosViolations.set(hasTosViolations);
    }

    @Input({required: true})
    set orderTime(value: Instant | null) {
        this._orderTime.set(value);
    }

    @Input({required: true})
    set pilotBoardingTime(value: Instant | null) {
        this._pilotBoardingTime.set(value);
    }

    readonly ActionsToDeliverPilot = ActionsToDeliverPilot;

    constructor(private readonly injector: Injector) {
    }

    ngOnInit(): void {
        const actionSelection = controlValuesSignal(this.form.controls.selection, {injector: this.injector});
        effect(() => {
            if (this._hasTosViolations()) {
                const orderTime = this._orderTime();
                const pilotBoardingTime = this._pilotBoardingTime();

                if (pilotBoardingTime == null || orderTime == null || pilotBoardingTime.isAfter(orderTime)) {
                    this.showForm.set(false);
                    this.form.disable();
                } else {
                    this.showForm.set(true);
                    this.form.enable();

                    const custom = actionSelection() === ActionsToDeliverPilot.CUSTOM;
                    this.showDetails.set(custom);
                    toggleEnabled(this.form.controls.custom, custom);
                }


            } else {
                this.showForm.set(false);
                this.form.disable();
            }
        }, {injector: this.injector});
    }

    protected readonly NOT_NEEDED_TEXT = NOT_NEEDED_TEXT;
}

export type PilotDeliveryActionsForm = FormGroup<{
    selection: FormControl<ActionsToDeliverPilot | null>;
    custom: FormControl<string>;
}>;

enum ActionsToDeliverPilot {
    NOT_NEEDED = "NOT_NEEDED",
    CUSTOM = "CUSTOM"
}

const NOT_NEEDED_TEXT: string = "Ei aiheudu haittaa";

export function resolveActionsTakenToDeliverPilot(form: PilotDeliveryActionsForm): string | null {
    if (form.enabled) {
        if (form.controls.selection.value === ActionsToDeliverPilot.NOT_NEEDED) {
            return NOT_NEEDED_TEXT;
        } else {
            return form.controls.custom.value;
        }
    } else {
        return null;
    }
}

export function newPilotDeliveryActionsForm(): PilotDeliveryActionsForm {
    return new FormGroup({
        selection: new FormControl<ActionsToDeliverPilot | null>(null, Validators.required),
        custom: new FormControl<string>("", {nonNullable: true, validators: Validators.required}),
    });
}
