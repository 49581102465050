import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BerthId, EndpointId } from "apina-frontend";
import { SelectBerthComponent } from "../../common/select-berth/select-berth.component";

@Component({
    selector: 'app-berth-field',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        SelectBerthComponent,
    ],
    templateUrl: './berth-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        "class": "block"
    }
})
export class BerthFieldComponent {

    label = input.required<string>();
    control = input.required<FormControl<BerthId | null>>();
    endpointId = input.required<EndpointId | null | undefined>();
    hint = input<string | null>(null);
}
