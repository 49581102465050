import { ChangeDetectionStrategy, Component, computed, Input, signal } from "@angular/core";
import { AbstractMatFormFieldControl, PilotageScheduleSource, pilotageScheduleSourceFullNames } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { MatFormFieldControl } from "@angular/material/form-field";
import { LabeledValue } from "../../domain/labeled-value";
import { MatSelectModule } from "@angular/material/select";

@Component({
    selector: "app-select-schedule-source",
    templateUrl: "./select-schedule-source.component.html",
    providers: [
        { provide: MatFormFieldControl, useExisting: SelectScheduleSourceComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatSelectModule,
        ReactiveFormsModule,
    ]
})
export class SelectScheduleSourceComponent extends AbstractMatFormFieldControl<PilotageScheduleSource> {

    private static nextId = 0;

    readonly control = new FormControl<PilotageScheduleSource | null>(null);
    private readonly _type = signal<SelectScheduleSourceType>(SelectScheduleSourceType.ALL);

    @Input()
    set type(type: SelectScheduleSourceType) {
        this._type.set(type);
    }

    readonly sources = computed(() => selectableSourcesForType(this._type()));

    get value(): PilotageScheduleSource | null {
        return this.control.value;
    }

    set value(id: PilotageScheduleSource | null) {
        if (id !== this.value) {
            this.control.setValue(id);
            this.stateChanges.next();
        }
    }

    constructor() {
        super("app-select-schedule-source", SelectScheduleSourceComponent.nextId++);
    }

    protected override onDisabled(disabled: boolean): void {
        if (disabled)
            this.control.disable();
        else
            this.control.enable();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        this.control.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(fn);
    }
}

export enum SelectScheduleSourceType {
    ALL,
    ORDER,
    NOTICE,
    EDIT_NOTICE_OR_ORDER
}

function selectableSourcesForType(type: SelectScheduleSourceType): LabeledValue<PilotageScheduleSource>[] {
    const result: PilotageScheduleSource[] = [
        PilotageScheduleSource.BROKER,
        PilotageScheduleSource.BROKER_FOC,
        PilotageScheduleSource.SHIP,
        PilotageScheduleSource.SHIP_FOC,
    ];

    switch (type) {
        case SelectScheduleSourceType.ALL:
            result.push(
                PilotageScheduleSource.AIS,
                PilotageScheduleSource.PORTNET,
                PilotageScheduleSource.HARBOR,
                PilotageScheduleSource.PREV_PORT,
                PilotageScheduleSource.FINNPILOT_ORDER_CENTER
            );
            break;
        case SelectScheduleSourceType.NOTICE:
            result.push(PilotageScheduleSource.HARBOR);
            break;
        case SelectScheduleSourceType.ORDER:
            break;
        case SelectScheduleSourceType.EDIT_NOTICE_OR_ORDER:
            result.push(
                PilotageScheduleSource.AIS,
                PilotageScheduleSource.HARBOR,
                PilotageScheduleSource.PREV_PORT,
            );
            break;
    }

    return result.map(it => ({value: it, label: pilotageScheduleSourceFullNames[it]}));
}
