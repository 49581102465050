import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AbstractMatFormFieldControl, tugsRequestedLabels } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { MatFormFieldControl } from "@angular/material/form-field";
import { TugsRequested } from "apina-frontend";
import { MatSelectModule } from "@angular/material/select";

@Component({
    selector: "app-select-tugs-requested",
    templateUrl: "./select-tugs-requested.component.html",
    providers: [
        { provide: MatFormFieldControl, useExisting: SelectTugsRequestedComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatSelectModule,
        ReactiveFormsModule,
    ]
})
export class SelectTugsRequestedComponent extends AbstractMatFormFieldControl<TugsRequested> {

    private static nextId = 0;

    readonly control = new FormControl<TugsRequested | null>(null);

    readonly options: TugsRequestedInfo[] = [
        {value: TugsRequested.NONE, label: tugsRequestedLabels[TugsRequested.NONE], count: 0},
        {value: TugsRequested.ONE, label: tugsRequestedLabels[TugsRequested.ONE], count: 1},
        {value: TugsRequested.TWO, label: tugsRequestedLabels[TugsRequested.TWO], count: 2},
        {value: TugsRequested.THREE, label: tugsRequestedLabels[TugsRequested.THREE], count: 3},
        {value: TugsRequested.FOUR, label: tugsRequestedLabels[TugsRequested.FOUR], count: 4},
        {value: TugsRequested.ONE_ASD, label: tugsRequestedLabels[TugsRequested.ONE_ASD], count: 1},
        {value: TugsRequested.TWO_CONV, label: tugsRequestedLabels[TugsRequested.TWO_CONV], count: 2},
        {value: TugsRequested.TWO_ASD, label: tugsRequestedLabels[TugsRequested.TWO_ASD], count: 2},
        {value: TugsRequested.ONE_CONV_ONE_ASD, label: tugsRequestedLabels[TugsRequested.ONE_CONV_ONE_ASD], count: 2},
        {value: TugsRequested.THREE_CONV, label: tugsRequestedLabels[TugsRequested.THREE_CONV], count: 3},
        {value: TugsRequested.THREE_ASD, label: tugsRequestedLabels[TugsRequested.THREE_ASD], count: 3},
        {value: TugsRequested.TWO_CONV_ONE_ASD, label: tugsRequestedLabels[TugsRequested.TWO_CONV_ONE_ASD], count: 3},
        {value: TugsRequested.ONE_CONV_TWO_ASD, label: tugsRequestedLabels[TugsRequested.ONE_CONV_TWO_ASD], count: 3},
        {value: TugsRequested.FOUR_CONV, label: tugsRequestedLabels[TugsRequested.FOUR_CONV], count: 4},
        {value: TugsRequested.FOUR_ASD, label: tugsRequestedLabels[TugsRequested.FOUR_ASD], count: 4},
        {value: TugsRequested.ONE_CONV_THREE_ASD, label: tugsRequestedLabels[TugsRequested.ONE_CONV_THREE_ASD], count: 4},
        {value: TugsRequested.TWO_COND_TWO_ASD, label: tugsRequestedLabels[TugsRequested.TWO_COND_TWO_ASD], count: 4},
        {value: TugsRequested.THREE_CONV_ONE_ASD, label: tugsRequestedLabels[TugsRequested.THREE_CONV_ONE_ASD], count: 4},
    ];

    get value(): TugsRequested | null {
        return this.control.value;
    }

    set value(id: TugsRequested | null) {
        if (id !== this.value) {
            this.control.setValue(id);
            this.stateChanges.next();
        }
    }

    constructor() {
        super("app-select-tugs-requested", SelectTugsRequestedComponent.nextId++);
    }

    protected override onDisabled(disabled: boolean): void {
        if (disabled)
            this.control.disable();
        else
            this.control.enable();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        this.control.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(fn);
    }

    labelFor(value: TugsRequested | null): string {
        return value == null ? "-" : tugsRequestedLabels[value];
    }
}

interface TugsRequestedInfo {
    value: TugsRequested,
    label: string;
    count: number;
}
